<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <VerifySteps email="true" phone="true" info="true" progress="100" />
      </div>
    </div>
  </div>
</template>

<script>
// import LoadingIcons from '../../common/LoadingIcons.vue';
// import ErrorFormField from '../../common/error/ErrorFormField.vue';
import VerifySteps from './VerifySteps';
export default {
  name: 'BasicInfo',
  components: {
    //LoadingIcons, ErrorFormField,
    VerifySteps,
  },
};
</script>

<style scoped></style>
